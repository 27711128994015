module.exports = class Invoice {
	constructor (invoiceId, invoiceDate, dueDate, clientId, invoiceYear, invoiceMonth, taxPercent, discountPercent, unitPricePerPatient, paymentTerms, paymentMethods, additionalInfo, status) {
		this.invoiceId = invoiceId;
		this.invoiceDate = invoiceDate;
		this.dueDate = dueDate;
		this.clientId = clientId;
		this.invoiceYear = invoiceYear;
		this.invoiceMonth = invoiceMonth;
		this.taxPercent = taxPercent;
		this.discountPercent = discountPercent;
		this.unitPricePerPatient = unitPricePerPatient;
		this.paymentTerms = paymentTerms;
		this.paymentMethods = paymentMethods;
		this.additionalInfo = additionalInfo;
		this.status = status;
	}
};
