<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-card flat style="border-radius: 12px" elevation="6">
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8"
                ><h5 class="d-flex align-left">{{ $t("invoices-menu") }}</h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search-invoice')"
                  single-line
                  hide-details
                  filled
                  dense
                  background-color="#E8E8E8"
                  rounded
                  class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table v-if="allInvoices"
			dense
            :headers="headers"
			:no-data-text="$t('no-data-available')"
			:loading-text="$t('loading')"
            :loading="loading"
            :search="search"
            :items="allInvoices"
			:items-per-page="-1"
            group-by="clientName"
            style="cursor: pointer"
            :height="testHeight"
              ref="table"
              :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
          >
            <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
              <td @click="toggle" :colspan="headers.length" style="background-color:white;" class="elevation-3">
                <v-row class="align-center">
                  <v-col class="py-0 my-0" cols="3">
                <span class="float-left"
                      style="font-weight: bold; margin-left: 2px; margin-right: 8px"
                >{{ group }}</span
                >
                  </v-col>
                  <v-col class="py-0 my-0" cols="3">
                    <v-chip  class="mb-1 white--text" pill x-small color="primary">
                      {{ items.length }}
                    </v-chip>
                  </v-col>
                  <v-col class="py-0 my-0">
                           <span style="float: right;">
                    <v-btn small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                    <v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                    </v-btn>

                   </span>
                  </v-col>
                </v-row>

              </td>
            </template>
            <!-- <template v-slot:item.actions="{ item }">
              <v-icon @click="invoiceDetails(item)" style="cursor:pointer; margin-left:15px;" class="float-right" color="primary">mdi-pencil</v-icon>
            </template> -->
			<template v-slot:item="{ item }">
				<!-- <tr @click="invoiceDetails(item)"> -->
				<tr>
					<td class="table-cols">
						{{ item.invoiceNumber }}
					</td>
					<td class="table-cols">
						{{ item.invoiceDate }}
					</td>
					<td class="table-cols">
						{{ item.grandTotal }}
					</td>
					<td class="table-cols">
						{{ verifiersInvoiceStatus != null ? (verifiersInvoiceStatus.find(v => v.lowValue === item.status).shortDescription) : '' }}
					</td>
					<td class="table-cols">
						<v-icon @click="invoiceDetails(item)" style="cursor:pointer; margin-left:15px;" class="float-right" color="primary">mdi-pencil</v-icon>
					</td>
				</tr>
			</template>
            <template v-slot:[`footer.prepend`]>
              <v-btn
                v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER'"
                class="table-create-button"
                label="Test"
                @click="addInvoice"
              >
				<img class="pt-1" height="20px" width="20px" :src="hospital_white" style="margin-right:3%; margin-bottom: 5%;"/>
                <span style="text-transform: none;">{{ $t("add-new-invoice") }}</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
		<InvoicesDialog
			v-if="dialog"
			:dialog="dialog"
			:type="dialogType"
			:selectedInvoice="selectedInvoice"
			@false="invoiceUpdated"
			@close="closeDialog"
		/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import InvoicesDialog from '../dialogs/InvoicesDialog.vue';
import { hospitalWhite } from '@/assets';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	components: {
		InvoicesDialog,
	},
	data: () => ({
		dialog: false,
		// dialogInvoice: false,
		dialogType: null,
		loading: false,
		togglers: {},
		search: '',
		headerClass: 'black_inner--text !important',
		expanded: [],
		hospital_white: hospitalWhite,
		selectedInvoice: null,
	}),
	computed: {
		...mapState({
			allInvoices: (state) => state.invoices.allInvoices,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			delay: 'authentication/getDelay',
		}),
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		headers () {
			var headers = [];
			headers = [
				{
					text: this.$t('header-invoice-number'),
					value: 'invoiceNumber',
					class: this.headerClass,
				},
				{
					text: this.$t('header-invoice-date'),
					value: 'invoiceDate',
					class: this.headerClass,
				},
				{
					text: this.$t('header-invoice-grand-total'),
					value: 'grandTotal',
					class: this.headerClass,
				},
				{
					text: this.$t('header-invoice-status'),
					value: 'status',
					class: this.headerClass,
				},
				{
					value: 'actions', // Use a unique value for the 'value' property
					class: this.headerClass,
				},
			];
			return headers;
		},
		verifiersInvoiceStatus () {
			if (this.verifiersFromRepo !== null) {
				const verifiersInvoiceStatus = this.verifiersFromRepo.filter(v => v.rvDomain === 'INVOICE_STATUS');
				return verifiersInvoiceStatus;
			} else {
				return [];
			}
		},
	},
	async mounted () {
		await this.getVerifiers();
		await this.getInvoices();
		// this.closeAll();
	},
	methods: {
		toggleHeaders () {
			const table = this.$refs.table;
			if (table) {
				const keys = Object.keys(table.$vnode.componentInstance.openCache);
				keys.forEach((x) => {
					table.$vnode.componentInstance.openCache[x] = false;
				});
			}
		},
		async getVerifiers () {
			const header = { domainsList: 'INVOICE_STATUS' };
			await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
		},
		async getInvoices () {
			this.toggleHeaders();
			this.loading = true;
			await this.$store.dispatch('invoices/getInvoices').then(() => {
				this.loading = false;
			});
			this.loading = false;
			this.toggleHeaders();
		},
		addInvoice () {
			this.dialog = true;
			this.dialogType = 'add';
			this.selectedInvoice = null;
		},
		invoiceDetails (item) {
			this.dialog = true;
			this.dialogType = 'edit';
			this.selectedInvoice = item;
		},
		closeDialog () {
			this.dialog = false;
			this.dialogType = null;
			this.selectedInvoice = null;
			this.getInvoices();
		},
		invoiceUpdated (res) {
			if (res.resFlag) {
				showSuccessAlert(this.$t('success'));
			} else {
				showErrorAlert(this.$t('failed'));
			}
			this.dialog = false;
			this.dialogType = null;
			this.selectedInvoice = null;
			this.getInvoices();
		},
	},
};
</script>
